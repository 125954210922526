import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Formik, Field } from "formik";
import { withTranslation, useTranslation } from "react-i18next";
import { Typography } from "antd";
import * as Yup from "yup";
import Input from "../../components/Input";
import Dot from "../../components/Dot";
import actions from "../Layout/actions";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../../src/resources/images/Logo-White.png";

const { Title, Paragraph } = Typography;

function LoginPage({ authToken, history, postLogin, msgEmail, isLoading }) {
  const el = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.homeReducer.isAuthenticated
  );
  const isPasswordChangeSuccess = useSelector(
    (state) => state.homeReducer.isPasswordChangeSuccess
  );
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
  });
  const [
    initialChangePasswordValues,
    setInitialChangePasswordValues,
  ] = useState({
    currentEmail: "",
    currentPassword: "",
    newPassword: "",
  });

  const validationSchema = Yup.object({
    email: Yup.string().required(
      t("message.msgRequired", { fieldName: t("login.email") })
    ),
    password: Yup.string().required(
      t("message.msgRequired", { fieldName: t("login.password") })
    ),
  });

  useLayoutEffect(() => {
    el.current.click();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/home");
    }
  }, [isAuthenticated]);

  // useEffect(() => {
  //   if (authToken) {
  //     getToken(authToken);
  //     history.push("/");
  //   }
  //   document.title = `${t('login.title_page_browser')} | ${t('common.title_app')}`;
  // }, [authToken]);

  const onSubmit = (values) => {
    dispatch(actions.authenticatedStart(values));
  };

  const submitForm = (formik) => (e) => {
    formik.handleSubmit();
    e.preventDefault();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      key="'login'"
    >
      {(formik) => (
        <div className="login">
          <img src={logo} alg="Logo" />
          <div className="login__wrapper">
            <div className="login__form">
              <p ref={el} className="login__form--title">
                {t("login.title")}
              </p>
              <form onSubmit={submitForm}>
                <Field
                  name="email"
                  type="email"
                  inputLength={255}
                  component={Input}
                  label={t("login.email")}
                  activeClass={formik.values.email && "control__active"}
                  isRequired={true}
                />
                <Field
                  name="password"
                  type="password"
                  inputLength={20}
                  component={Input}
                  label={t("login.password")}
                  activeClass={formik.values.password && "control__active"}
                  isRequired={true}
                />
                {isLoading ? (
                  <Dot />
                ) : (
                  <button
                    type="primary"
                    onClick={formik.handleSubmit}
                    className="primary ant-btn login__form--button"
                  >
                    {t("login.btnLogin")}
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
export default withTranslation()(LoginPage);
