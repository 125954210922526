import firebase from "firebase";
import admin, { messaging } from "firebase-admin";
import { notification } from "antd";

admin.initializeApp();

export const initializeFirebase = () => {
  var firebaseConfig = {
    apiKey: "AIzaSyBurW8xj6kCMTyhACKfrAkeVY1bl7mKO9A",
    authDomain: "russelunoviral.firebaseapp.com",
    databaseURL: "https://russelunoviral-default-rtdb.firebaseio.com",
    projectId: "russelunoviral",
    storageBucket: "russelunoviral.appspot.com",
    messagingSenderId: "623075757421",
    appId: "1:623075757421:web:000494ba32800796b75e64",
    measurementId: "G-4KKN1Z0MVY",
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
};

export const login = (body) => {
  const { email, password } = body;
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((userCredential) => {
      // Signed in

      openNotification("success", "Login successed");
      return userCredential;
    })
    .catch((err) => {
      openNotification("warning", err.message);
    });
};

export const getCurrentUser = () => {
  return firebase.auth().currentUser;
};

export const subscribeToTopic = (token) => {
  const registrationTokens = [token];

  admin
    .messaging()
    .subscribeToTopic(registrationTokens, "topic")
    .then((response) => {
      console.log("Successfully subscribed to topic:", response);
    })
    .catch((error) => {
      console.log("Error subscribing to topic:", error);
    });
};

export const unsubscribeToTopic = (token) => {
  const registrationTokens = [token];

  admin
    .messaging()
    .unsubscribeFromTopic(registrationTokens, "topic")
    .then(function (response) {
      console.log("Successfully unsubscribed from topic:", response);
    })
    .catch(function (error) {
      console.log("Error unsubscribing from topic:", error);
    });
};

export const changePassword = (values) => {
  const { currentEmail, currentPassword, newPassword } = values;

  return firebase
    .auth()
    .signInWithEmailAndPassword(currentEmail, currentPassword)
    .then((userd) => {
      console.log(userd);
      const user = firebase.auth().currentUser;
      return user
        .updatePassword(newPassword)
        .then(() => {
          openNotification("success", "Password updated!");
          return true;
        })
        .catch((error) => {
          openNotification("warning", error.message);
          return false;
        });
    })
    .catch((error) => {
      openNotification("warning", error.message);
      return false;
    });
};

export const logout = () => {
  return firebase
    .auth()
    .signOut()
    .then(() => {
      return true;
    })
    .catch(() => {
      return true;
    });
};

const openNotification = (type, message) => {
  notification[type]({
    message,
  });
};
