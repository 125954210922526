import React, { useEffect, useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { Row, Typography, Col, Button } from "antd";
import { Formik, Field } from "formik";
import Input from "../../components/Input";
import TextArea from "../../components/TextArea";
import { CONTACT_FORM_TEXTAREA_MAXLENGTH } from "../../common/constants";
import { sendNotificationApi } from "../../services/sendNotificationService";
import { getCurrentUser, logout } from "../../services/authenticationService";
import { useDispatch } from "react-redux";
import actions from "../Layout/actions";

import * as Yup from "yup";
import HeaderComponent from "../../components/Header";
import { rest } from "lodash";

const { Title, Paragraph } = Typography;

function Home({ history }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState({
    title: "",
    content: "",
    url: "",
    urlImage: "",
  });

  const validationSchema = Yup.object({
    title: Yup.string()
      .required(
        t("message.msgRequired", { fieldName: t("contact-form.title") })
      )
      .max(50, t("contact-form.field-to-long")),
    content: Yup.string()
      .required(
        t("message.msgRequired", { fieldName: t("contact-form.content") })
      )
      .max(500, t("contact-form.field-to-long")),
    url: Yup.string().matches(
      /https?:\/\/(www.)?[-a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
      t("contact-form.not-a-link")
    ),

    urlImage: Yup.string().matches(
      /https?:\/\/(www.)?[-a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
      t("contact-form.not-a-link")
    ),
  });

  const _sendNotification = async (values, { resetForm }) => {
    const user = getCurrentUser();
    if (user.refreshToken) {
      try {
        const { title, content, url, urlImage } = values;
        const params = {
          title,
          content,
          url,
          urlImage,
        };
        const reposive = await sendNotificationApi(params);
        if (!!reposive) {
          resetForm();
        }
      } catch (e) {}
    }
  };

  return (
    <>
      <HeaderComponent />
      <div id="home">
        <div className="container">
          <div id="contact-form">
            <Formik
              initialValues={initialValues}
              onSubmit={_sendNotification}
              validationSchema={validationSchema}
            >
              {(formik) => (
                <>
                  <Row gutter={16}>
                    <Col className="gutter-row" xs={24}>
                      <Field
                        label={t("contact-form.title")}
                        name="title"
                        id="title"
                        component={Input}
                        wrapperClass="input"
                        activeClass={formik.values.title && "control__active"}
                        isRequired={true}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col className="gutter-row" xs={24}>
                      <Field
                        label={t("contact-form.url")}
                        name="url"
                        id="url"
                        component={Input}
                        wrapperClass="input"
                        activeClass={formik.values.url && "control__active"}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col className="gutter-row" xs={24}>
                      <Field
                        label={t("contact-form.urlImage")}
                        name="urlImage"
                        id="urlImage"
                        component={Input}
                        wrapperClass="input"
                        activeClass={
                          formik.values.urlImage && "control__active"
                        }
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col className="gutter-row" xs={24}>
                      <Field
                        label={t("contact-form.content")}
                        name="content"
                        id="content"
                        component={TextArea}
                        wrapperClass="textarea"
                        rows={7}
                        maxLength={CONTACT_FORM_TEXTAREA_MAXLENGTH}
                        activeClass={formik.values.content && "control__active"}
                        isRequired={true}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col
                      className="gutter-row"
                      style={{ textAlign: "center", marginTop: "20px" }}
                      xs={24}
                    >
                      <button
                        onClick={formik.handleSubmit}
                        type="submit"
                        className="btn-glow--red"
                      >
                        {t("contact-form.submit")}
                      </button>
                    </Col>
                  </Row>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(Home);
