import React, { useEffect, useRef } from "react";

function TextArea({
  field,
  form: { touched, errors },
  wrapperClass,
  label,
  activeClass,
  isRequired,
  ...props
}) {
  const textAreaRef = useRef(null);
  useEffect(() => {
    textAreaRef.current.addEventListener("input", autoResize, false);

    function autoResize() {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = this.scrollHeight + 1 + "px";
    }

    return () => textAreaRef.current.removeEventListener("input", autoResize);
  }, []);

  return (
    <div className={`input`}>
      <div className="input__wrapper">
        <textarea
          className={activeClass}
          ref={textAreaRef}
          id="textarea"
          {...field}
          {...props}
        />
        {label && (
          <label htmlFor={field.name}>
            {label}
            {isRequired && <span className="require-field">*</span>}
          </label>
        )}
      </div>
      {errors[field.name] && touched[field.name] && (
        <span className="required">{errors[field.name]}</span>
      )}
    </div>
  );
}

export default TextArea;
