import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n/i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { persistor, store, sagaMiddleware } from './redux/configStore';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import rootSaga from './redux/rootSaga';

import 'antd/dist/antd.css';

const onBeforeLift = (store) => () => {};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate onBeforeLift={onBeforeLift(store)} loading={null} persistor={persistor}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
sagaMiddleware.run(rootSaga);
serviceWorker.unregister();
