import Homepage from "./pages/Home";
import LoginPage from "./pages/Login";
import ChangePasswordPage from "./pages/ChangePassword";

const authenticatedRoutes = [
  {
    name: "Home",
    path: "/home",
    component: Homepage,
    exact: true,
  },
  {
    name: "ChangePassword",
    path: "/change-password",
    component: ChangePasswordPage,
    exact: true,
  },
];

const unauthenticatedRoutes = [
  {
    name: "Login",
    path: "/",
    component: LoginPage,
    exact: true,
  },
];

export { unauthenticatedRoutes, authenticatedRoutes };
