import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { unauthenticatedRoutes, authenticatedRoutes } from "../../Routes";
import { Layout } from "antd";
import NotFound from "../../pages/NotFound";
import AuthenticatedRoute from "../../components/AuthenticatedRoute";
import UnauthenticatedRoute from "../../components/UnauthenticatedRoute";
import { initializeFirebase } from "../../services/authenticationService";

const { Content } = Layout;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
    };
  }

  componentDidMount() {
    this.screenResize();
    window.addEventListener("resize", this.screenResize);

    initializeFirebase();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.screenResize);
  }

  screenResize = () => {
    this.setState({
      isMobile: window.innerWidth < 992,
    });
  };

  render() {
    const { isMobile } = this.state;
    const { isAuthenticated } = this.props;
    return (
      <Layout className="layout">
        <Layout>
          <Content>
            <Switch>
              {unauthenticatedRoutes.map((route, index) => (
                <UnauthenticatedRoute
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                  key={index}
                  appProps={{ isAuthenticated }}
                />
              ))}
              {authenticatedRoutes.map((route, index) => (
                <AuthenticatedRoute
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                  key={index}
                  appProps={{ isAuthenticated }}
                />
              ))}
              <Route path="*" component={NotFound} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mstp = (state) => ({
  isAuthenticated: state.homeReducer.isAuthenticated,
});

const mdtp = (dispatch) => ({});

export default connect(mstp, mdtp)(withRouter(App));
