import { validateStatus } from '../utils/validateStatus';

const axios = require('axios');

const HEADERS = {
  'Content-Type': 'application/json',
};

const getFullUrl = (url) => {
  if (!url.startsWith('/')) {
    url = '/' + url;
  }
  return `${process.env.REACT_APP_API_URL}` + url;
};

const baseApi = {
  get: (endpoint, params = {}) => {
    return axios.default
      .get(getFullUrl(endpoint), {
        params: params,
        headers: HEADERS,
        validateStatus,
      })
      .then(
        (response) => {
          return response.data;
        },
        (err) => {
          return err.response || err;
        }
      )
      .catch(({ response }) => {
        const err = new Error(JSON.stringify(response));
        throw err;
      });
  },
  post: (endpoint, params) => {
    console.log(endpoint);
    return axios.default
      .post(getFullUrl(endpoint), params, {
        headers: HEADERS,
        validateStatus,
      })
      .then((response) => {
        return response.data;
      })
      .catch(({ response }) => {
        const err = new Error(JSON.stringify(response));
        throw err;
      });
  },
};

export { baseApi };
