import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import { withTranslation, useTranslation } from "react-i18next";
import { Typography } from "antd";
import * as Yup from "yup";
import Input from "../../components/Input";
import Dot from "../../components/Dot";
import actions from "../Layout/actions";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser } from "../../services/authenticationService";
import logo from "../../resources/images/Logo-White.png";

const { Title, Paragraph } = Typography;

function ChangePasswordPage({
  authToken,
  history,
  postLogin,
  msgEmail,
  isLoading,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const isPasswordChangeSuccess = useSelector(
    (state) => state.homeReducer.isPasswordChangeSuccess
  );
  const [
    initialChangePasswordValues,
    setInitialChangePasswordValues,
  ] = useState({
    currentPassword: "",
    newPassword: "",
  });

  useEffect(() => {
    const user = getCurrentUser();
    if (user) {
      setUser(user);
    }
  }, []);

  useEffect(() => {
    if (isPasswordChangeSuccess) {
      setInitialChangePasswordValues({
        ...initialChangePasswordValues,
        currentEmail: "",
        currentPassword: "",
        newPassword: "",
      });
      history.push("/home");
    }
  }, [isPasswordChangeSuccess]);

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required(
      t("message.msgRequired", { fieldName: t("login.currentPassword") })
    ),
    newPassword: Yup.string().required(
      t("message.msgRequired", { fieldName: t("login.newPassword") })
    ),
  });

  // useEffect(() => {
  //   if (authToken) {
  //     getToken(authToken);
  //     history.push("/");
  //   }
  //   document.title = `${t('login.title_page_browser')} | ${t('common.title_app')}`;
  // }, [authToken]);

  const onChangePassword = (values) => {
    const formValues = { currentEmail: user.email, ...values };
    dispatch(actions.changePasswordStart(formValues));
  };

  const submitForm = (formik) => (e) => {
    formik.handleSubmit();
    e.preventDefault();
  };

  const goBack = () => {
    history.push("/home");
  };

  return (
    <Formik
      initialValues={initialChangePasswordValues}
      onSubmit={onChangePassword}
      validationSchema={validationSchema}
      key="'change-password'"
    >
      {(formik) => (
        <div className="login">
          <img src={logo} alg="Logo" />
          <div className="login__wrapper">
            <div className="login__form">
              <p className="login__form--title">{t("login.changepassword")}</p>
              <form onSubmit={submitForm}>
                <Field
                  name="currentPassword"
                  type="password"
                  label={t("login.currentPassword")}
                  inputLength={255}
                  component={Input}
                  activeClass={
                    formik.values.currentPassword && "control__active"
                  }
                  isRequired={true}
                />
                <Field
                  name="newPassword"
                  type="password"
                  label={t("login.newPassword")}
                  inputLength={20}
                  component={Input}
                  activeClass={formik.values.newPassword && "control__active"}
                  isRequired={true}
                />
                {isLoading ? (
                  <Dot />
                ) : (
                  <button
                    type="primary"
                    onClick={formik.handleSubmit}
                    className="primary ant-btn login__form--button"
                  >
                    {t("login.btnChangePassword")}
                  </button>
                )}
                <button
                  type="primary"
                  onClick={goBack}
                  className="primary ant-btn login__form--button"
                >
                  {t("login.txt_loginBack")}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
export default withTranslation()(ChangePasswordPage);
