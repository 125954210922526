import React, { Fragment } from "react";
import { Input } from "antd";

const { Search } = Input;

function InputComponent({
  field,
  form: { touched, errors },
  keyItem,
  wrapperClass,
  isSearchInput = false,
  errorMessage = "",
  required = true,
  innerRef,
  label,
  activeClass,
  isRequired,
  ...props
}) {
  return (
    <div className="input">
      <div className={`input__wrapper`}>
        {!isSearchInput ? (
          <Fragment>
            {keyItem && <span className="key">{keyItem}.</span>}
            {required ? (
              <input
                className={activeClass}
                required
                ref={innerRef}
                {...field}
                {...props}
              />
            ) : (
              <input
                className={activeClass}
                ref={innerRef}
                {...field}
                {...props}
              />
            )}
            <label>
              {label}
              {isRequired && <span className="require-field">*</span>}
            </label>
          </Fragment>
        ) : (
          <Search {...field} {...props} />
        )}
      </div>
      {<span className="required">{errorMessage[field.name]}</span>}
      {errors[field.name] && touched[field.name] && (
        <span className="required">{errors[field.name]}</span>
      )}
    </div>
  );
}

export default InputComponent;
