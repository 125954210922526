import React, { Fragment, useEffect, useState } from "react";
import { Input } from "antd";
import { Header } from "antd/lib/layout/layout";
import { Menu, Dropdown } from "antd";
import { DownOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { getCurrentUser, logout } from "../../services/authenticationService";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import actions from "../../pages/Layout/actions";
import { Avatar } from "antd";
import logo from "../../resources/images/logo.png";

function HeaderComponent() {
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = async () => {
    const response = await logout();
    if (response) {
      dispatch(actions.logout());
      history.push("/");
    }
  };
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <UserOutlined />
        <Link to="/change-password">Change Password</Link>
      </Menu.Item>
      <Menu.Item key="1">
        <LogoutOutlined />
        <div onClick={handleLogout}>Logout</div>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const user = getCurrentUser();
    if (user) {
      setUser(user);
      console.log(user);
    }
  }, []);
  return (
    <Header>
      <Link to="/home">
        <img src={logo} alt="Logo" />
      </Link>
      <div>
        <Dropdown overlay={menu} trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            {user.email}
          </a>
        </Dropdown>
        <Avatar
          style={{
            backgroundColor: "#ffbf00",
          }}
          size="large"
        >
          {user.displayName || "U"}
        </Avatar>
      </div>
    </Header>
  );
}

export default HeaderComponent;
