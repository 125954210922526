import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Router, Route, Switch } from "react-router-dom";
import { browserHistory } from "./utils/history";
import Layout from "./pages/Layout";
import { useSelector } from "react-redux";
import Dot from "./components/Dot";
import "./App.css";
import "./sass/_app.scss";

function App() {
  const isLoading = useSelector((state) => state.homeReducer.isLoading);

  return (
    <div className="App bg-app">
      <>
        <Router history={browserHistory}>
          <Switch>
            <Layout />
          </Switch>
        </Router>
        {isLoading && (
          <div className="event-loading">
            <div className="loading">
              <Dot />
            </div>
          </div>
        )}
      </>
    </div>
  );
}

export default withTranslation()(App);
