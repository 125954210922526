export const ActionTypes = {
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
  AUTHENTICATED_START: "AUTHENTICATED_START",
  AUTHENTICATED_SUCCESS: "AUTHENTICATED_SUCCESS",
  AUTHENTICATED_FAIL: "AUTHENTICATED_FAIL",
  CHANGE_PASSWORD_START: "CHANGE_PASSWORD_START",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",
  LOGOUT: "LOGOUT",
};
export const actions = {
  changeLanguage: (body) => {
    return {
      type: ActionTypes.CHANGE_LANGUAGE,
      body: body,
    };
  },
  authenticatedStart: (body) => {
    return {
      type: ActionTypes.AUTHENTICATED_START,
      body: body,
    };
  },
  authenticatedSuccess: (body) => {
    return {
      type: ActionTypes.AUTHENTICATED_SUCCESS,
      payload: body,
    };
  },
  authenticatedError: (error) => {
    return {
      type: ActionTypes.AUTHENTICATED_FAIL,
      payload: error,
    };
  },
  changePasswordStart: (body) => {
    return {
      type: ActionTypes.CHANGE_PASSWORD_START,
      body: body,
    };
  },
  changePasswordSuccess: (body) => {
    return {
      type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
      payload: body,
    };
  },
  changePasswordError: (error) => {
    return {
      type: ActionTypes.CHANGE_PASSWORD_FAIL,
      payload: error,
    };
  },
  logout: () => {
    return {
      type: ActionTypes.LOGOUT,
    };
  },
};

export default actions;
