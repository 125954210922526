import { ActionTypes } from "./actions";

const DEFAULT_STATE = {
  language: "en",
  isAuthenticated: false,
  isLoading: false,
  isPasswordChangeSuccess: false,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.body,
      };
    case ActionTypes.AUTHENTICATED_START:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
      };
    case ActionTypes.AUTHENTICATED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
      };
    case ActionTypes.AUTHENTICATED_FAIL:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
      };
    case ActionTypes.CHANGE_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
        isPasswordChangeSuccess: false,
      };
    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isPasswordChangeSuccess: true,
      };
    case ActionTypes.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
        isPasswordChangeSuccess: false,
      };
    case ActionTypes.LOGOUT: {
      return {
        ...state,
        isAuthenticated: false,
      };
    }
    default:
      return state;
  }
};
