import { put, takeLatest, all } from "redux-saga/effects";
import { actions, ActionTypes } from "./actions";
import { login, changePassword } from "../../services/authenticationService";

function* authenticated(data) {
  try {
    const [response] = yield all([login(data.body)]);
    if (response.user) {
      yield put(actions.authenticatedSuccess(response));
    } else {
      yield put(actions.authenticatedError(response));
    }
  } catch (e) {
    let errorResponse = {};
    try {
      errorResponse = JSON.parse(e.message);
    } catch (error) {
      errorResponse = {
        statusText: "500",
        data: "Internal Server Error",
      };
    }
    yield put(actions.authenticatedError(e));
  }
}

function* changePasswordStart(data) {
  try {
    const [response] = yield all([changePassword(data.body)]);
    console.log(response);
    if (response) {
      yield put(actions.changePasswordSuccess());
    } else {
      yield put(actions.changePasswordError());
    }
  } catch (e) {
    let errorResponse = {};
    try {
      errorResponse = JSON.parse(e.message);
    } catch (error) {
      errorResponse = {
        statusText: "500",
        data: "Internal Server Error",
      };
    }
    yield put(actions.changePasswordError());
  }
}

export function* watchAuthentication() {
  yield takeLatest(ActionTypes.AUTHENTICATED_START, authenticated);
  yield takeLatest(ActionTypes.CHANGE_PASSWORD_START, changePasswordStart);
}
