import { baseApi } from "./api";
import Config from "../common/config";
import { notification } from "antd";

export const sendNotificationApi = (params) => {
  return baseApi
    .post("/notification", params)
    .then((res) => {
      if (res && res.code == 1) {
        openNotification("success", "Send notification success!");
        return true;
      } else {
        openNotification("warning", "Send notification failed!");
        return false;
      }
    })
    .catch((err) => {
      openNotification("warning", "Send notification failed!");
      return false;
    });
};

const openNotification = (type, message) => {
  notification[type]({
    message,
  });
};
